.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: #282c34;
  min-height: 100vh;
  text-align: center;
}

.welcome {
  color: white;
  font-size: calc(50px + 2vmin);
  font-weight: 200;
  text-transform: uppercase;
}

/* .App-logo { */
/*   animation: App-logo-spin infinite 20s linear; */
/*   height: 40vmin; */
/* } */

/* .App-header { */
/*   background-color: #282c34; */
/*   min-height: 100vh; */
/*   display: flex; */
/*   flex-direction: column; */
/*   align-items: center; */
/*   justify-content: center; */
/*   font-size: calc(10px + 2vmin); */
/*   color: white; */
/* } */

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin { */
/*   from { */
/*     transform: rotate(0deg); */
/*   } */
/*   to { */
/*     transform: rotate(360deg); */
/*   } */
/* } */

main {
  background-color: #282c34;
  background: #090909;
  min-height: 100vh;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #111;
  height: 80px;
  padding: 10px 20px;
}

header h1 {
  color: orange;
  font-weight: 300;
  text-transform: uppercase;
}

header input[type=text],
header select {
  font-size: 12px;
  height: 20px;
  padding: 10px;
}
