* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.tracks {
  display: flex;
  flex-direction: 'row';
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;


  color: #333;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Georgia, sans-serif;
  padding: 10px 0 50px
}

.track {
  display: flex;
  flex-direction: column;

  background: #222;
  border: 3px solid #000;
  cursor: pointer;
  margin: 10px;
  width: 200px;
}

.track img {
  height: 200px;
  width: 100%;
}

.track .info {
  margin: 7px;
}

.track .name {
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
}

.track .artist {
  color: #999;
  font-size: 14px;
}

.track .name,
.track .artist {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.active {
  border: 3px dashed orange;
}

@media only screen and (max-width: 600px) {
  .tracks {
    flex-direction: column;
  }

  .track {
    flex-direction: row;
    background: inherit;
    border: none;
    height: 80px;
    margin-top: 0;
    width: calc(100% - 20px);
  }

  .track img {
    height: 80px;
    width: 80px;
  }

  .active {
    background: #222;
    border: 0;
  }
}
