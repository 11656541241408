.controllers {
  display: flex;
  align-items: center;
  justify-content: center;

  background: #111;

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 60px;
}

.controllers button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #111;
  border: none;
  outline: none;
}

.controllers .play {
  border-radius: 50%;
  height: 90px;
  margin: 0 20px;
  width: 90px;
}

svg.icon {
  cursor: pointer;
}

button svg.icon {
  fill: orange;
}

button:disabled svg {
  fill: gray;
}

.repeats {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 20px;
  top: 15px;
}

svg.repeat {
  fill: #444;
}

.activated {
  fill: orangered !important;
}
